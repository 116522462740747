
  import { Component, Vue } from 'vue-property-decorator'
  import ListUsers from '../components/user/ListUsers.vue'

  @Component({ components: { ListUsers } })
  export default class AdminPage extends Vue {
    throwError() {
      throw new Error('Sentry Error')
    }
  }
