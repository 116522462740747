
  import { Component, Vue } from 'vue-property-decorator'
  import ListTeacher from './ListTeacher.vue'
  import ListStudents from './ListStudents.vue'

  @Component({ components: { ListTeacher, ListStudents } })
  export default class ListUsers extends Vue {
    activeName = 'teacher'

    handleClick(tab: any, event: Event) {
      console.log('tab', tab)
      console.log('event', event)
    }
  }
